import { SET_SNACKBAR, SET_LOADING, CLEAR_LOADING } from "./action-types";

export const setSnackbar = (variant, message, open = true) => (dispatch) => {
  return dispatch({
    type: SET_SNACKBAR,
    payload: { open, variant, message },
  });
};

export const setLoading = (name, isLoading = true) => (dispatch) => {
  return dispatch({
    type: SET_LOADING,
    payload: { name, isLoading },
  });
};

export const clearLoading = () => (dispatch) => {
  return dispatch({
    type: CLEAR_LOADING,
  });
};
